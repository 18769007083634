<template>
  <div class="faq-setting-list" :class="{'p-3': userId > 0, 'fixed-draggable-dynamic-table-wrapper-height': $route.name === 'faqSetting'}">
    <draggable-dynamic-table ref="faqList"
                             class="faq-setting-list"
                             :in-modal="$route.name !== 'faqSetting'"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             @row:clicked="handleShowFAQ"/>


    <!-- edit faq prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editFAQPromptStatus"
      @close="editFAQPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('EditFAQBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.faq.edit.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editFAQPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <edit-faq :faq="selectedFaq" @edit="handleEditFaq" @delete="handleDeleteFaq"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- insert faq prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertFAQPromptStatus"
      @close="insertFAQPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertFAQBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.faq.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertFAQPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-faq @insert="handleInsertFaq"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="insertFaq" v-show="false" @click="insertFAQPromptStatus = true"/>
    <button id="saveFaq" v-show="false" @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
  import {getAppSetting, editAppSetting} from '@/http/requests/settings/setting'
  import EditFaq from "../edit/editFaq";
  import CustomIcon from "../../../../../components/customIcon/customIcon";
  import InsertFaq from "../insert/insertFaq";

  export default {
    name: 'faqList',
    components: {InsertFaq, CustomIcon, EditFaq, DraggableDynamicTable},
    metaInfo () {
      return {
        title: this.$t('setting.faq.title')
      }
    },
    props: {
      userId: 0
    },
    data() {
      return {
        insertFAQPromptStatus: false,
        editFAQPromptStatus: false,
        selectedFaq: '',
        options: {
          id: 'faqList',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'answer',
            i18n: 'setting.faq.table.header.answer',
            width: 'calc((100% / 3) * 2)',
            minWidth: 300,
            align: 'center',
            locked: true,
            sortable: true
            /*footer: {
              type: 'auto-counter',
            }*/
          },
          {
            field: 'question',
            i18n: 'setting.faq.table.header.question',
            width: 'calc((100% / 3))',
            minWidth: 120,
            align: 'center',
            locked: true,
            sortable: true
          }
        ],
        user: null,
        data: [],
        filters: {},
        sorts: ['order[0]=created_at,desc'],
        page: 1,
        loadingTimer: 0,
        endedList: false,
        actions: {
          toolbar: [
            {
              id: 'insertFaq',
              icon: 'PLUS',
              iconPack: 'useral',
              color: 'success'
            }
          ],
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: 'Settings'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      }
    },
    created() {
      if (this.$route.name === 'Settings') {
        this.actions.leftToolbar.splice(0, 1)
      }
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
      })
      this.$store.dispatch('setPageTitle', this.$t('setting.faq.title'))

      this.getFaqList()
    },
    methods: {
      getFaqList() {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.faqList && this.data.length === 0) {
              this.$refs.faqList.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.faqList && this.data.length > 0) {
              this.$refs.faqList.loadMoreStatus = 'Loading'
            }

            getAppSetting().then(response => {
              const club = response.data.data
              const faq = JSON.parse(club.faq)
              if (faq && faq.questions.length > 0) {
                let data = []
                faq.questions.forEach((item) => {
                  data.push({
                    id: data.length + 1,
                    question: item.question || '',
                    answer: item.answer || ''
                  })
                })

                this.data = data
              }

            }).catch((error) => {
              if (this.$refs.faqList) this.$refs.faqList.loadMoreStatus = 'Danger'
            })
          }
        }, 500)
      },
      handleShowFAQ(row) {
        this.selectedFaq = {
          id: this.data.indexOf(row),
          payload: row
        }
        this.editFAQPromptStatus = true
      },
      handleEditFaq(row) {
        if (row.id >= 0) {
          this.data[row.id] = {
            question: row.payload.question,
            answer: row.payload.answer
          }
          const data = this.data
          this.data = []
          setTimeout(() => {
            this.data = data
            this.sendData()
          }, 100)
          // this.editFAQPromptStatus = false
        }
      },
      handleDeleteFaq(id) {
        if (id >= 0) {
          this.data.splice(id, 1)
        }
        this.sendData()
        // this.insertFAQPromptStatus = false
        // this.editFAQPromptStatus = false
      },
      handleInsertFaq(row) {
        this.data.unshift({
          question: row.question,
          answer: row.answer
        })
        // const data = this.data
        // this.data = []
        // setTimeout(() => {
        //     this.data = data
        // }, 100)
        this.sendData()
        // this.insertFAQPromptStatus = false
      },
      sendData() {
        const questions = {
          questions: this.data
        }
        if (this.data.length > 0) {
          const payload = {
            faq: JSON.stringify(questions)
          }
          editAppSetting(payload).then(response => {
            this.$vs.notify({
              time: 2400,
              title: this.$t('alert.message.title'),
              text: this.$t('setting.notifications.editFaq.success'),
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'success'
            })
            this.editFAQPromptStatus = false
            this.insertFAQPromptStatus = false
          }).catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                time: 2400,
                title: this.$t('alert.error.title'),
                text: error.response.data.message || error.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            }
          })
        }
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .faq-setting-list {

    &:not(.fixed-draggable-dynamic-table-wrapper-height) {
      height: 100%;
    }
  }
</style>
