<template>
  <div class="useral-edit-faq">
    <vs-divider class="md:hidden"/>

    <div class="view-side mt-3 sm:mt-0">
      <div v-show="tabIndex !== 4"
           class="view-box">
        <vs-row>
          <vs-col>
            <div>
              <custom-validate-input :label="$t('setting.faq.labels.question')"
                                     :classes="{'w-full': true}"
                                     align="right"
                                     rtl
                                     v-model="question"/>

              <custom-validate-textarea :label="$t('setting.faq.labels.answer')"
                                        :classes="{'w-full': true}"
                                        align="right"
                                        rtl
                                        v-model="answer"/>

            </div>

            <vs-button id="deleteUserBTN"
                       class="w-full mt-3"
                       color="danger"
                       @click="$refs.deleteConfirmation.showDialog()">
              {{ $t('setting.faq.labels.delete') }}
            </vs-button>

            <custom-dialog ref="deleteConfirmation"
                           :title="$t('setting.faq.confirmations.delete.title')"
                           :body="$t('setting.faq.confirmations.delete.body', {name: question.value})"
                           @accept="deleteUser"/>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <vs-button v-show="false"
               id="EditFAQBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
  import CustomValidateTextarea from '../../../../../components/customInput/customValidateTextarea'
  import CustomDialog from "../../../../../components/customDialog/customDialog";

  export default {
    name: 'editFaq',
    components: {
      CustomDialog,
      CustomValidateTextarea,
      CustomValidateInput
    },
    props: {
      faq: ''
    },
    data() {
      return {
        tabIndex: 0,
        question: {
          value: '',
          isValid: true
        },
        answer: {
          value: '',
          isValid: true
        }
      }
    },
    mounted() {
      this.question = {
        value: this.faq.payload.question || '',
        isValid: true
      }
      this.answer = {
        value: this.faq.payload.answer || '',
        isValid: true
      }
    },
    methods: {
      getFaq() {
        this.question = {
          value: this.faq.payload.question.value || '',
          isValid: true
        }
        this.answer = {
          value: this.faq.payload.answer.value || '',
          isValid: true
        }
      },
      deleteUser() {
        this.$emit('delete', this.faq.id)
      },
      sendData() {
        if (this.question.isValid && this.answer.isValid) {
          const faq = {
            id: this.faq.id,
            payload: {
              question: this.question.value,
              answer: this.answer.value
            }
          }
          this.$emit('edit', faq)
        }
      }
    }
  }
</script>

<style lang="scss">
  .useral-edit-faq {
    width: 100%;
    height: calc(100% - 10px);
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }

    .menu-side {
      width: 200px;
      min-width: 200px;
      height: 100%;
      padding: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        padding: 0;
        height: fit-content;
      }

      ul {

        li {
          position: relative;
          border: 1px solid #cecece;
          border-radius: .5rem;
          margin-bottom: 10px;
          padding: 0 10px;
          line-height: 40px;
          cursor: pointer;
          transition: all 0.3s ease;

          .icon-chevron-left {
            position: absolute;
            height: fit-content;
            top: 0;
            bottom: 0;
            right: 6px;
            margin: auto;
          }

          &:hover {
            transform: translateX(5px);
          }
        }
      }
    }

    .view-side {
      flex-grow: 1;
      height: 100%;
      border: 1px solid #cecece;
      padding: 5px;
      border-radius: .5rem;
      overflow-y: auto;


      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        height: calc(100% - 230px);
      }

      .view-box {
        width: 100%;
        height: 100%;
        padding: 5px;
        overflow: auto;

        .custom-profile-image-input {
          label {
            height: 110px;
            width: 110px;
          }
        }

        .date-picker-item {
          border: 0 !important;
          border-radius: 0.4rem;
          position: relative;

          .date-picker-label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 5px;
            padding: 0 5px;
            background-color: #ffffff;
            z-index: 1;
          }

          .vpd-input-group {
            width: 20px !important;
            position: absolute;
            top: 6px;
            left: 2px;

            .vpd-icon-btn {
              opacity: 1 !important;
              height: 25px !important;
              width: 20px !important;
              background-color: inherit !important;

              svg {
                fill: #000000;
                overflow: visible !important;
              }
            }

            .form-control {
              border: 0;
              width: 100%;
              display: none;
              line-height: 35px;
            }
          }
        }

        .input-field-item {
          position: relative;
          border: 1px solid #cdcdcd;
          border-radius: 0.4rem;
          padding: 5px 10px;
          margin: 15px 0;
          display: flex;
          justify-content: space-around;
          min-height: 35px;

          &.invalid {
            border-color: #b00000;

            .input-field-label {
              color: #b00000;
            }
          }

          .input-field-label {
            position: absolute;
            font-size: 12px;
            top: -10px;
            left: 8px;
            background-color: #ffffff;
            padding: 0 5px;
          }

          .radio-item {
            display: flex;
            align-items: center;
            direction: rtl;

            label {
              margin-left: 5px;
            }
          }
        }

        div.edit-user-field {
          position: relative;

          span.label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 7px;
            z-index: 10000;
            background: #ffffff;
            padding: 0 5px;
          }

          .useral-custom-element-select {
            margin: 15px 0;

            .selected {
              height: 35px;
              line-height: 35px;
              font-size: 13px;
            }

            .items {
              line-height: 35px;

              div {
                height: 35px;
                font-size: 13px;
              }
            }
          }
        }

        .error-alert {
          display: inline-block;
          border: 1px solid #ffba00;
          border-radius: .5rem;
          color: #ffba00;
          padding: 0 10px;
          line-height: 50px;
          height: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;

          i {
            font-size: 15px;
          }
        }

        &::-webkit-scrollbar {
          display: block;
          border-radius: .2rem;
          width: 10px;
          background: #f9f9fd;
          right: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          border-radius: .2rem;
          background: #cecece;
        }
      }
    }
  }
</style>
